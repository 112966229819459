<template>
  <div class="church-communications template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcLocationCommunications }}</h1>
      <BackJump @reload_host="pageLoad()" :obj="backJumpObj" :vis="true" :i18n="translations.components"></BackJump>
    </page-header>
    <!-- / Page Header -->
    <page-body class="mx-lg">
      <section class="section-1">
        <div class="d-flex section-header">
          <h2>{{ translations.tcAddresses }}</h2>
          <b-button v-if="iCanSeeAny(secured_address_add_controls)" variant="primary" @click="handleAddAddressClick">{{
          translations.tcAddAddress
          }}</b-button>
        </div>
        <div class="d-flex flex-wrap" v-if="translations.components">
          <ServiceCard v-for="(address, index) in locationCommunicationsData.addresses" :i18n="translations.components"
            :key="index" :obj="address"
            :secure_edit_keys="{ ...secured_address_edit_controls, ...secured_address_add_controls }"
            :secure_delete_keys="{ ...secured_address_delete_controls, ...secured_address_add_controls }"
            @edit_requested="handleAddressEditRequest(address)"
            @delete_approved="handleDeleteRequest(address.adr_key, 2)"></ServiceCard>
        </div>
      </section>
      <section class="section-2">
        <div class="d-flex section-header">
          <h2>{{ translations.tcCommunications }}</h2>
          <b-button v-if="iCanSeeAny(secured_phone_fax_add_controls)" variant="primary" @click="handleAddPhoneFaxClick">
            {{ translations.tcAddPhoneOrFax }}</b-button>
          <b-button variant="primary" v-if="iCanSeeAny(secured_email_add_controls)" @click="handleAddEmailClick">{{
          translations.tcAddEmail
          }}</b-button>
        </div>
        <div class="d-flex flex-wrap" v-if="translations.components">
          <ServiceCard v-for="(phone, index) in locationCommunicationsData.phones" :i18n="translations.components"
            :key="index" :obj="phone"
            :secure_edit_keys="{ ...secured_phone_fax_edit_controls, ...secured_phone_fax_add_controls }"
            :secure_delete_keys="{ ...secured_phone_fax_delete_controls, ...secured_phone_fax_add_controls }"
            @edit_requested="handlePhoneFaxEditRequest(phone)" @delete_approved="handleDeleteRequest(phone.phn_key, 1)">
          </ServiceCard>
          <ServiceCard v-for="(email, index) in locationCommunicationsData.emails" :i18n="translations.components"
            :key="index + 50" :obj="email"
            :secure_edit_keys="{ ...secured_email_edit_controls, ...secured_email_add_controls }"
            :secure_delete_keys="{ ...secured_email_delete_controls, ...secured_email_add_controls }"
            @edit_requested="handleEmailEditRequest(email)" @delete_approved="handleDeleteRequest(email.eml_key, 0)">
          </ServiceCard>
        </div>
      </section>
    </page-body>
    <!-- / Page Body -->
    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJumpLocation.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ServiceCard from '@/components/service-card/ServiceCard.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'location-communications',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      secured_email_add_controls: {
        add_aux_facility_email_button: '4d9d000a-99d1-4728-8d96-95722193729d',
        add_scripture_placement_facility_email_button: '97fffd3e-f63e-47cf-aef7-17d5b4a32d80',
      },
      secured_email_delete_controls: {
        delete_aux_facility_email_button: '3a001bf0-c6b3-4aa5-a99a-572e0735dfe4',
        delete_scripture_placement_facility_email_button: 'fb40aa1c-db26-40eb-bfa2-ced5cda03054',
      },
      secured_email_edit_controls: {
        edit_aux_facility_email_button: '9a6a6b5b-3c13-4fa6-a5f7-6d1f8230dfb4',
        edit_scripture_placement_facility_email_button: '5475b084-60d2-48ae-bbc4-fe298a4e425f',
      },
      secured_phone_fax_edit_controls: {
        edit_aux_facility_phone_fax_button: '444c9fb3-cfc2-47df-a8a6-95738203fc03',
        edit_scripture_placement_facility_phone_fax_button: 'ec95d203-ae46-4a64-9950-0bad04750282',
      },
      secured_phone_fax_add_controls: {
        add_aux_facility_phone_fax_button: '40d198bc-f9fb-4b66-a4e6-e7bf76e9bae0',
        add_scripture_placement_facility_phone_fax_button: '1576cd4f-b505-453a-be79-58a6a78a60f5',
      },
      secured_phone_fax_delete_controls: {
        delete_aux_facility_phone_fax_button: '8651741c-c0d6-4cc7-8e2d-959b7bc4068c',
        delete_scripture_placement_facility_phone_fax_button: 'eaf51c8f-cfa7-490a-bbee-1155a2ba0d5b',
      },
      secured_address_add_controls: {
        add_aux_facility_address_button: 'b3d91db0-ce41-4e02-b167-651703a6ccd0',
        add_scripture_placement_facility_address_button: '01d0fc9f-003d-4011-b20b-b07fa3b22be7',
      },
      secured_address_edit_controls: {
        edit_aux_facility_address_button: 'd7f8f7a2-a17a-47d6-a556-363b044ca81a',
        edit_scripture_placement_facility_address_button: '0bbe50ac-49a3-4d60-aa87-54860db867fa',
      },
      secured_address_delete_controls: {
        delete_aux_facility_address_button: '9609540e-5238-47f0-bd8a-f22a2ec5a33d',
        delete_scripture_placement_facility_address_button: 'ae356065-5c3f-4e86-8f2e-5ed70a825579',
      },
      backJumpObj: {
        link: '#',
        location: '',
      },
    }
  },
  methods: {
    ...mapActions({
      deleteChurchEmailPhoneAddress: 'churchMinistry/deleteChurchEmailPhoneAddress',
      getLocationCommunications: 'scriptureDistribution/getLocationCommunications',
      loadLocationDetails: 'scriptureDistribution/loadLocationDetails',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedAddress: 'user/setSelectedAddress',
      setSelectedEmail: 'user/setSelectedEmail',
      setSelectedPhone: 'user/setSelectedPhone',
    }),
    async handleAddAddressClick() {
      await this.setSelectedAddress(this.emptyAddress)
      this.$router.push('/programs/sd/location-profile/add-edit-location-address/link')
    },
    async handleAddEmailClick() {
      await this.setSelectedEmail(this.emptyEmail)
      this.$router.push('/programs/sd/location-profile/add-edit-location-email/link')
    },
    async handleAddPhoneFaxClick() {
      await this.setSelectedPhone(this.emptyPhoneFax)
      this.$router.push('/programs/sd/location-profile/add-edit-location-phone-fax/link')
    },
    async handleAddressEditRequest(address) {
      await this.setSelectedAddress(address)
      this.$router.push('/programs/sd/location-profile/add-edit-location-address/link')
    },
    async handleEmailEditRequest(email) {
      await this.setSelectedEmail(email)
      this.$router.push('/programs/sd/location-profile/add-edit-location-email/link')
    },
    async handlePhoneFaxEditRequest(phone) {
      await this.setSelectedPhone(phone)
      this.$router.push('/programs/sd/location-profile/add-edit-location-phone-fax/link')
    },
    async handleDeleteRequest(key, sw) {
      let result = true
      this.setLoadingStatus(true)
      await Promise.all([
        (result = await this.deleteChurchEmailPhoneAddress({
          key: key,
          sw: sw,
        })),
        await this.pageLoad(),
      ]).then(() => {
        this.$swal({
          icon: result ? 'success' : 'error',
          showCancelButton: false,
          text: result ? this.translations.tcDeleteSuccessful : this.translations.tcDeleteFailed,
          confirmButtonText: this.translations.tcOk,
        })
      })
    },
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.loadLocationDetails({ org_key: this.userSelectedLocationKey, org_type_key: this.userSelectedOrgTypeKey }),
          await this.getLocationCommunications(this.userSelectedLocationKey),
        ]).then(() => {
          this.backJumpObj.location = this.locationDetail.org_name
          this.backJumpObj.link = '/programs/sd/location-profile'
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  computed: {
    ...mapGetters({
      emptyAddress: 'churchMinistry/empty_address',
      emptyEmail: 'churchMinistry/empty_email',
      emptyPhoneFax: 'churchMinistry/empty_phone_fax',
      iCanSeeAny: 'user/iCanSeeAny',
      locationCommunicationsData: 'scriptureDistribution/locationCommunicationsData',
      locationDetail: 'scriptureDistribution/locationDetail',
      prefCulture: 'user/userPreferredCulture',
      userSelectedLocationKey: 'user/userSelectedLocationKey',
      userSelectedOrgTypeKey: 'user/userSelectedOrgTypeKey',
    }),
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('back-jump-location', 'quick-jump-locations', 'service-card'),
        this.getComponentTranslations(
          'common.address-types',
          'common.attire',
          'common.church-service-type',
          'common.days',
          'common.email-types',
          'common.fax-types',
          'common.months-abbreviations',
          'common.phone-types'
        ),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
      })
      await this.pageLoad()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    BackJump: BackJump,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ServiceCard: ServiceCard,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.church-communications {
  header h1 {
    padding-bottom: 80px;

    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }

  .section-header {
    margin-bottom: 45px;
    align-items: center;

    @include breakpoint(sm) {
      flex-wrap: wrap;
    }

    h2 {
      font-size: 42px;
      font-family: $DIN;
      line-height: 44px;
      letter-spacing: 1px;
      color: #000;
      margin-right: 30px;
      margin-bottom: 0;
      text-transform: uppercase;

      @include breakpoint(sm) {
        margin-bottom: 35px;
      }
    }

    .btn {
      margin-right: 10px;
      display: flex;
      align-items: center;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .section-1,
  .section-2 {
    padding-left: 25px;
    padding-right: 25px;

    @include breakpoint(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .section-1 {
    margin-bottom: 75px;
    margin-top: 30px;

    @include breakpoint(sm) {
      margin-bottom: 15px;
    }
  }

  .section-2 {
    margin-bottom: 20px;
  }
}
</style>
